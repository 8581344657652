<div class="resource-item">
  <div class="resource-image">
    <img class="card-img-top" *ngIf="item.image" src="{{global.get_thumbnail(item.image)}}">
    <img class="card-img-top" *ngIf="!item.image"src="./assets/img/default/resource-default-3.jpg">
    <div>
      <p>{{item.title}}</p>
    </div>
  </div>
  <div class="resource-title">
    {{item.title}}
  </div>
</div>
