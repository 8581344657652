<h5 class="card-title">Información</h5>
<div class="lagash-detail">
  <div>
    <div class="lagash-detail-title">Incluye</div>
    <div>
      <small class="badge badge-info ml-1" *ngFor="let bring of item.brings">
        {{bring}}
      </small>
    </div>
  </div>
  <div>
    <div class="lagash-detail-title">Ilustraciones</div>
    <div>
      <small class="badge badge-info ml-1" *ngFor="let illustration of item.illustrations">
      {{illustration}}
    </small>
    </div>
  </div>
  <div>
    <div class="lagash-detail-title">ISBN</div>
    <div>{{item.isbn || 'NO EXISTE'}}</div>
  </div>
  <div>
    <div class="lagash-detail-title">Tamaño</div>
    <div>{{item.high + ' x ' + item.width}}</div>
  </div>
  <div>
    <div class="lagash-detail-title">Paginas</div>
    <div>{{item.pages || 'NO EXISTE'}}</div>
  </div>
  <div>
    <div class="lagash-detail-title">Fecha de creación</div>
    <div>{{item.created | date : 'MM dd yyyy'}}</div>
  </div>
  <div>
    <div class="lagash-detail-title">Año</div>
    <div>{{item.year}}</div>
  </div>
</div>
