<div fxLayout="row">
  <div class="toolbar">
    <app-catalog-list [title]="catalog_title"
                      [items]="catalogs"
                      (on_clean_up)="reset()"
                      (on_select)="go_to_catalog($event)">
    </app-catalog-list>
  </div>
  <div fxFlex="1 1 auto">
    <div class="container mb-4">
      <div class="row">
        <div class="col-9">
          <app-search-input-basic [query]="query"
                                  (search)="search()">
          </app-search-input-basic>
        </div>
        <div class="col-3">
          <app-pagination [query]="query"
                          (pagination)="pagination($event)">
          </app-pagination>
        </div>
      </div>
    </div>
    <div class="container">
      <app-search-empty [items]="items"></app-search-empty>
    </div>
    <app-resources-list-view [items]="items" (select_item)="go_to_item($event)">
    </app-resources-list-view>
  </div>
</div>
