<div class="container">
  <div class="jumbotron jumbotron-fluid">
    <div class="container">
      <h1 class="display-4">Organización</h1>
      <p class="lead">Sistema de clasificación decimal M. Dewey.</p>
      <p class="lead">Lista de encabezamiento de materias para bibliotecas; Compilado por Carmen Rovira y Jorge Aguayo.</p>
      <p class="lead">Tabla alfabética de C.A. Cutter`s.</p>
      <p class="lead">ärea de circulación.</p>
      <p class="lead">área de procesos técnicos.</p>
    </div>
  </div>
</div>
