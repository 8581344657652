<div class="container">
  <div class="jumbotron jumbotron-fluid">
    <div class="container">
      <h1 class="display-4">Historia</h1>
      <p class="lead">
        La biblioteca nace en forma conjunta con la Universidad Adventista de Bolivia (UAB), en agosto de 1991, bajo la dirección profesional del bibliotecólogo Walter Meza Rivera. El fondo bibliográfico inicial perteneció al Colegio Secundario.
      </p>
      <p class="lead">
        A partir del 3 de septiembre del 2003 lleva el nombre de Biblioteca SIGHART KLAUSS, nuevo edificio inaugurado por el Dr. Jan Paulsen Presidente de la Asociación General de la Iglesia Adventista del 7mo Día.
      </p>
      <p class="lead">
        A lo largo de la historia de la Universidad Adventista de Bolivia (UAB). Se suscitaron diferentes acontecimientos que apoyaron la misión educativa de la institución. Una de ellas fue la implementación de la Biblioteca Virtual. La iniciativa de contar con una Biblioteca Virtual en la UAB. surgió a fines del año 2004 en la gestión del Dr. Roberto Pereyra, Rector de la UAB. quien invitó al Dr. Hernán Hammerly para elaborar el proyecto de la Biblioteca Virtual en coordinación con el Director de la Biblioteca Sighart Klauss, Btgo. Walter Meza R. Para la conformación del equipo de gestión, se asigna como asistente al Ing. Víctor Pérez Rojas.
      </p>
      <p class="lead">
        Actualmente  la Biblioteca  Sighart Klauss  cuenta  con 36133 libros, 7210 revistas y suscripciones a periódicos; trabajo de investigación (tesis, proyectos  educativos y monografías 4163). La Biblioteca Virtual cuenta con la colección e-libro: 80345  libros, 910 revistas, 4597 tesis, 166 monografías, 22816 artículos, 3276 apuntes digitalizados y con acceso  a 1400 revistas  científicas del  Programa para el Fortalecimiento de la Información  para la Investigación (PERI), financiada por el Viceministerio de Ciencia y Tecnología.  Toda la información está disponible  a todos los usuarios  interesados en aprender,  conocer e investigar para lograr la excelencia  académica, cumpliendo de esta manera  con uno más de los objetivos  de la Universidad Adventista de Bolivia  de ofrecer servicios y excelencia académica.
      </p>
    </div>
  </div>
</div>
