<div class="lagash-navbar-header">
  <app-header></app-header>
  <nav class="navbar navbar-expand-lg navbar-dark lagash-navbar">
    <div class="col-lg-2"></div>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link" routerLink="/main">BIBLIOTECA SIGHART KLAUSS<span class="sr-only">(current)</span></a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" routerLink="/search">Buscar</a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" routerLink="/books">Libros</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/thesis">Tesis</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/magazines">Revistas</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/newspapers">Periodicos</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Informacion
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" routerLink="/information/history">Historia</a>
            <a class="dropdown-item" routerLink="/information/mission">Misión y Visión</a>
            <a class="dropdown-item" routerLink="/information/objectives">Objetivos</a>
            <a class="dropdown-item" routerLink="/information/organization">Organización</a>
            <a class="dropdown-item" routerLink="/information/activities">Actividad cooperativa</a>
            <a class="dropdown-item" routerLink="/information/specialties">Especialidades</a>
            <a class="dropdown-item" routerLink="/information/services">Servicios y Atención</a>
            <a class="dropdown-item" routerLink="/information/schedule">Horario de atención</a>
            <a class="dropdown-item" routerLink="/information/requirements">Requisitos de Préstamo</a>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-lg-2">
      <ul class="navbar-nav mr-auto">
      </ul>
    </div>
  </nav>
</div>
<div fxFlex class="lagash-container">
  <router-outlet></router-outlet>
</div>
<!--
<div fxFlex fxLayout="column">
  <div fxFlex>1. One</div>
  <div fxFlex>2. Two</div>
  <div fxFlex>3. Three</div>
  <div fxFlex>4. Four</div>
</div>
<div fxFlex fxLayout="row" fxLayoutAlign="center center">
  <div fxFlex="1 1 auto">1. One</div>
  <div fxFlex="1 1 auto">2. Two</div>
  <div fxFlex="1 1 auto">3. Three</div>
  <div fxFlex="1 1 auto">4. Four</div>
</div> -->
