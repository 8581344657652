<div class="header-container">
  <div class="container">
    <div class="logo">
      <a routerLink="/"><img src="./assets/img/logo-uab-2021.png" /></a>
    </div>
    <div class="header-information">
      <div class="header-icon">
        <mat-icon>location_on</mat-icon>
      </div>
      <div>
        <h5>Av. Simón I. Patiño Km1</h5>
        <span>Vinto - Cochabamba - Bolivia</span>
      </div>
    </div>
    <div class="header-information">
      <div class="header-icon">
        <mat-icon>phone_enabled</mat-icon>
      </div>
      <div>
        <h5>800 10 91 91</h5>
        <p>Línea Gratuita</p>
      </div>
    </div>
    <div class="lagash-media">
      <a class="nav-link" routerLink="/loans">
        <div><mat-icon>bookmarks</mat-icon></div>
        <span>{{loans}}</span>
      </a>
    </div>
  </div>
</div>
