<div fxLayout="row" class="resource-view">
  <div fxFlex="1 1 auto">
    <div class="container mb-4">
      <div class="row">
        <div class="col-4">
          <h1>{{labels.title}}</h1>
          <div class="card">
            <img class="card-img-top" *ngIf="item.image" src="{{global.get_image(item.image)}}" alt="Card image cap">
            <img class="card-img-top" *ngIf="!item.image" src="./assets/img/default/resource-default-3.jpg" alt="Card image cap">
            <div class="card-body">
              <app-resource-information-view [item]="item">
              </app-resource-information-view>
            </div>
          </div>
        </div>
        <div class="col-8">
          <h2>{{item.title}}</h2>
          <div class="mb-4">
            Autor:
            <b *ngFor="let author of authors">
              |<small>
                {{author.last_name}} {{author.first_name}}
              </small>
            </b>
          </div>
          <div class="card bg-light  mb-3">
            <div class="card-header">Indice</div>
            <div class="card-body">
              <div *ngFor="let index of item.indexes">
                <u>
                  {{index}}
                </u>
              </div>
            </div>
          </div>
          <div class="card bg-light mb-3">
            <div class="card-header">Descriptores</div>
            <p class="card-body">
              <a href="#" class="badge badge-info lagash-tag-link mr-1" *ngFor="let tag of item.tags">{{tag}}</a>
            </p>
          </div>
          <div>
            <app-latest-resources [type]="type" (select_item_event)="go_to_item($event)">
            </app-latest-resources>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="toolbar">
    <app-ejemplares-view [_id]="_id"
                         [type]="type"
                         [item]="item"
                         [labels]="labels"
                         [authors]="authors">
    </app-ejemplares-view>
  </div>
</div>
