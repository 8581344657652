<div class="container">
  <div class="jumbotron jumbotron-fluid">
    <div class="container">
      <h1 class="display-4">Horario de atención</h1>
      <p class="lead">LUNES A JUEVES  8:00 - 12:30 y 14:00 - 21:00</p>
      <p class="lead">VIERNES  8:00 a 13:00</p>
      <p class="lead">DOMINGO  8:00 a 13:00</p>
    </div>
  </div>
</div>
