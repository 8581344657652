import { Component } from '@angular/core';

@Component({
  selector: 'module-books',
  templateUrl: './component.html',
  styleUrls: ['../../wargos.css', './component.css']
})
export class InformationComponent {
  constructor() {
  }
}
