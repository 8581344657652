import { Component } from '@angular/core';

@Component({
  selector: 'information-history',
  templateUrl: './component.html',
  styleUrls: ['./component.css']
})
export class InformationHistoryComponent {
  
  constructor() {
  }
}
