<div class="container">
  <div class="jumbotron jumbotron-fluid">
    <div class="container">
      <h1 class="display-4">Requisitos para el préstamo de libros (documentos actualizados)</h1>
      <p class="lead">Para estudiantes de la UAB (carnet de identidad o carnet de estudiante vigente).</p>
      <p class="lead">Para visitas (carnet de estudiante mas su carnet de identidad) el préstamo es sólo en sala.</p>
      <p class="lead">Para docentes (carnet de identidad o credencial)</p>
    </div>
  </div>
</div>
