<ngb-carousel *ngIf="images" class="lagash-coursel-images">
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img [src]="images[0]" alt="Random first slide">
    </div>
    <div class="carousel-caption">
      <h3>BIBLIOTECA SIGHART KLAUSS</h3>
      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img [src]="images[1]" alt="Random second slide">
    </div>
    <div class="carousel-caption">
      <h3>BIBLIOTECA SIGHART KLAUSS</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img [src]="images[2]" alt="Random third slide">
    </div>
    <div class="carousel-caption">
      <h3>BIBLIOTECA SIGHART KLAUSS</h3>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
    </div>
  </ng-template>
</ngb-carousel>
<div class="lagash-main-container container">
  <div class="jumbotron">
    <h1 class="display-4">Galeria de images</h1>
    <hr class="my-4">
    <p>Fotos de los pabellones de la biblioteca</p>
  </div>
  <section>
    <div class="row">
      <div class="col-lg-4">
        <a href="./assets/img/galeria/1.jpg" class="img-gal"><img class="img-fluid" src="./assets/img/galeria/1.jpg" alt=""></a>
        <a href="./assets/img/galeria/2.jpg" class="img-gal"><img class="img-fluid" src="./assets/img/galeria/2.jpg" alt=""></a>
      </div>
      <div class="col-lg-4">
        <a href="./assets/img/galeria/3.jpg" class="img-gal"><img class="img-fluid" src="./assets/img/galeria/3.jpg" alt=""></a>
        <a href="./assets/img/galeria/4.jpg" class="img-gal"><img class="img-fluid" src="./assets/img/galeria/4.jpg" alt=""></a>
      </div>
      <div class="col-lg-4">
        <a href="./assets/img/galeria/5.jpg" class="img-gal"><img class="img-fluid" src="./assets/img/galeria/5.jpg" alt=""></a>
        <a href="./assets/img/galeria/6.jpg" class="img-gal"><img class="img-fluid" src="./assets/img/galeria/6.jpg" alt=""></a>
      </div>
    </div>
  </section>
</div>
<div class="lagash-main-lists container">
  <div class="jumbotron">
    <h1 class="display-4">Libros recientes</h1>
    <hr class="my-4">
    <p>La biblioteca actualiza periodicamente los recursos bibiograficos</p>
  </div>
  <div id="style-1" class="images-list">
    <div *ngFor="let item of books" (click)="go_to_item(item, 'BOOK')">
      <img class="card-img-top" *ngIf="item.image" src="{{global.get_thumbnail(item.image)}}">
      <img class="card-img-top" *ngIf="!item.image"src="./assets/img/default/book-default.png">
    </div>
  </div>
  <div class="jumbotron">
    <h1 class="display-4">Tesis recientes</h1>
    <hr class="my-4">
    <p>La biblioteca actualiza periodicamente los recursos bibiograficos</p>
  </div>
  <div id="style-1" class="images-list">
    <div *ngFor="let item of theses" (click)="go_to_item(item, 'THESIS')">
      <img class="card-img-top" *ngIf="item.image" src="{{global.get_thumbnail(item.image)}}">
      <img class="card-img-top" *ngIf="!item.image"src="./assets/img/default/book-default.png">
    </div>
  </div>
</div>
<!-- <div class="container">
  <div class="lagash-main-stats">
    <div class="best">
      <h3>Libros más leídos</h3>
      <div *ngFor="let item of best_books" (click)="go_to_item(item, 'BOOK')">
        <mat-icon>location_on</mat-icon>{{item.title}}
      </div>
    </div>
    <div class="best">
      <h3>Tesis más leídos</h3>
      <div *ngFor="let item of best_books" (click)="go_to_item(item, 'THESIS')">
        <mat-icon>location_on</mat-icon> {{item.title}}
      </div>
    </div>
    <div class="stats">
      <div *ngFor="let item of stats">
        <span><mat-icon>location_on</mat-icon></span> {{item.total}} {{item.title}}
      </div>
    </div>
  </div>
</div>
<div style="clear: both;"></div> -->
