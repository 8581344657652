<div class="container">
  <div class="jumbotron jumbotron-fluid">
    <div class="container">
      <h1 class="display-4">Actividad cooperativa</h1>
      <p class="lead">Red de de Centros de Documentaciòn Cochabamba (REDOC).</p>
      <p class="lead">Red de Información Etnológica Boliviana(REDETBOL).</p>
      <p class="lead">Red de Centros de Documentación y bibliotecas cruceñas especializada en Ciencias Sociales y Biológicas.</p>
      <p class="lead">Asociación de Bibliotecarios.</p>
    </div>
  </div>
</div>
