<div class="container">
  <div class="jumbotron jumbotron-fluid">
    <div class="container">
      <h1 class="display-4">Visión</h1>
      <p class="lead">
        La Biblioteca Sighart Klauss de la "UAB", aspira aser una biblioteca moderna que privilegie el conocimiento de la verdad, como unidad de información innovadora en su programa de servicio.
      </p>
    </div>
  </div>
  <div class="jumbotron jumbotron-fluid">
    <div class="container">
      <h1 class="display-4">Misión</h1>
      <p class="lead">
        Promover el interés de la lectura de libros edificantes en los jóvenes, para formar caracteres sólidos en su formación integral.
      </p>
      <p class="lead">
        Promover la búsqueda del conocimiento por medio del uso de los recursos virtuales para mejorar los procesos de investigación y aprendizaje; y así contribuir con la calidad académica de la UAB
      </p>
    </div>
  </div>
</div>
