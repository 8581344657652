<div class="container">
  <div class="jumbotron jumbotron-fluid">
    <div class="container">
      <h1 class="display-4">Objetivos</h1>
      <p class="lead">Apoyar de manera efectiva a los programas de estudio, investigación y docencia de la universidad.</p>
      <p class="lead">Ser el centro de reunión de estudiantes y docentes en un ambiente de aprendizaje y formación académica.</p>
      <p class="lead">Ser fuente de ideas para el cambio, resolución de problemas e investigación.</p>
      <p class="lead">Difundir todo documento impreso.</p>
      <p class="lead">Satisfacer los intereses de lectura e investigación de los usuarios.</p>
      <p class="lead">Cooperar en el desarrollo de planes de extensión universitaria.</p>
      <p class="lead">Extablecer los nexos correspondientes para la vinculación a redes, locales e internacionales.</p>
      <p class="lead">Propiciar la disponibilidad de los recursos de la biblioteca.</p>
    </div>
  </div>
</div>
