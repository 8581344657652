<nav aria-label="Page navigation example">
  <ul class="pagination justify-content-end">
    <li class="page-item" *ngIf="query.page > 1">
      <a class="page-link" (click)="change_page(-1)">Anterior</a>
    </li>
    <li class="page-item disabled" *ngIf="query.page <= 1">
      <a class="page-link" (click)="change_page(-1)">Anterior</a>
    </li>
    <li class="page-item"><a class="page-link" href="#">{{query.page}}</a></li>
    <li class="page-item">
      <a class="page-link" (click)="change_page(1)">Siguiente</a>
    </li>
  </ul>
</nav>
