<div class="container container-lagash">
  <h1>Prestamos</h1>
  <div class="row">
    <div class="col-lg-3"></div>
    <div class="card mb-6 user" style="max-width: 600px;" *ngIf="user">
      <div class="row no-gutters">
        <div class="col-md-4">
          <img *ngIf="user.image" src="{{global.get_image(user.image)}}">
          <img *ngIf="!user.image" src="./assets/img/user.png">
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title">{{user.first_name}} {{user.last_name}}</h5>
            <p class="card-text">
              <b>Facultad:</b> {{get_faculty(user.faculty_id).name}}<br />
              <b>Carrera:</b> {{get_carrer(user.career_id).name}}
            </p>
            <p class="card-text"><small class="text-muted">{{user.card_id}}</small></p>
            <div class="options">
              <div class="primary-btn" (click)="clean()">Cambiar</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3"></div>
  </div>
  <div class="row">
    <div class="col-lg-3"></div>
    <div>
      <h2 *ngIf="user">{{user.name}}</h2>
      <div class="input-group mb-3" *ngIf="!user">
        <div class="input-group-prepend">
          <span class="input-group-text">Carnet de Estudiante or Identidad</span>
        </div>
        <input type="text" class="form-control" aria-label="Default" name="name" [(ngModel)]="token" aria-describedby="inputGroup-sizing-default">
        <div class="primary-btn" (click)="find()">Buscar</div>
      </div>
    </div>
    <div class="col-lg-3"></div>
  </div>
  <div class="row">
    <div class="col-2">
    </div>
    <div class="col-8">
      <div *ngIf="loans.length <= 0">
        <div class="alert alert-warning alert-dismissible fade show" role="alert">
          <strong>Ups!</strong> Utiliza el buscador y seleciona el material bibliografico que deseas prestarte.
        </div>
      </div>
      <div class="lagash-loans-list">
        <div class="" *ngFor="let loan of loans; let i = index">
          <div class="card mb-3">
            <img *ngIf="loan.item.material.image" class="card-img-top load-item-image" src="{{global.get_image(loan.item.material.image)}}">
            <img *ngIf="!loan.item.material.image" class="card-img-top load-item-image" src="./assets/img/default/resource-default-3.jpg">
            <div class="card-body">
              <h5 class="card-title">{{loan.item.material.title}}</h5>
              <p class="card-text options">
                <span class="btn btn-light" (click)="remove_loan(loan, i)" *ngIf="!loan.state">Quitar</span>
                <span class="btn btn-success" *ngIf="loan.state">guardado</span>
              </p>
              <div *ngIf="loan.item.authors.length > 0">
                <small>Autor: </small>
                <small *ngFor="let author of loan.item.authors"><b>|</b> {{author.last_name}} {{author.first_name}} </small>
              </div>
              <p class="card-text"><small class="text-muted">Codigo: <b>{{loan.code}}</b> Tipo: <b>{{I18N[loan.key]}}</b></small></p>
            </div>
          </div>
        </div>
      </div>
      <div class="lagash-loans-list">
        <div class="options loans">
          <div class="primary-btn" (click)="booking()">Reservar</div>
        </div>
      </div>
      <div class="alert alert-success" role="alert">
        <h4 class="alert-heading">Muy bien!</h4>
        <p>Al hacer una reserva a su nombre puede ir donde los encargados de los bloques para pedir que la busqueda el recurso bibliografico.</p>
        <hr>
      </div>
      <div class="alert alert-warning" role="alert">
        <p class="mb-0">Si los libros que hiso reserva no son recogidos, la reserva se eliminara en 3 dias.</p>
      </div>
    </div>
  </div>
</div>
