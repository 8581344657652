import { Injectable } from '@angular/core';

@Injectable()
export class UUID {

  constructor() {
  }
 
  next() {
    var code = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    });
    return code;
  }
}
