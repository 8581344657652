<div>
  <div class="input-group">
    <input type="text"
           class="form-control"
           aria-label="Default"
           aria-describedby="inputGroup-sizing-default"
           [(ngModel)]="query.search"
           (change)="search_items()">
    <div class="input-group-append">
      <span class="input-group-text btn-primary" (click)="search_items()">Buscar</span>
    </div>
  </div>
  <div class="lagash-radio mb-3">
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" [(ngModel)]="query.type" value="TITLE">
      <label class="form-check-label">Titulo</label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" [(ngModel)]="query.type" value="SUBJECT">
      <label class="form-check-label">Tema</label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" [(ngModel)]="query.type" value="AUTHOR">
      <label class="form-check-label">Autor</label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" [(ngModel)]="query.type" value="ALL">
      <label class="form-check-label">Todos</label>
    </div>
  </div>
</div>
