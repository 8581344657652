<div class="container">
  <div class="jumbotron jumbotron-fluid">
    <div class="container">
      <h1 class="display-4">Especialidades</h1>
      <p class="lead">
        Teología, Administración, Contabilidad, Economía, Auditoría, Finanzas, Ingenieria de Sistemas, Educación, Filosofía, Psicología, Enfermería, Fisioterapia, Educación Fisica, Ciencias Aplicadas y Puras.
      </p>
    </div>
  </div>
</div>
